@font-face {
    font-family: 'Oswald-Regular';
    src: local('Oswald-Regular'),
    url('../Assets/fonts/oswald/Oswald-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Oswald-Medium';
    src: local('Oswald-Medium'),
    url('../Assets/fonts/oswald/Oswald-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: local('Oswald-SemiBold'),
    url('../Assets/fonts/oswald/Oswald-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Oswald-Bold';
    src: local('Oswald-Bold'),
    url('../Assets/fonts/oswald/Oswald-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Oswald-Light';
    src: local('Oswald-Light'),
    url('../Assets/fonts/oswald/Oswald-Light.woff') format('woff');
}

@font-face {
    font-family: 'Oswald-ExtraLight';
    src: local('Oswald-ExtraLight'),
    url('../Assets/fonts/oswald/Oswald-ExtraLight.woff') format('woff');
}
