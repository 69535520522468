.img {
    max-width: 300px;
    max-height: 300px;
    padding: 10px;
}

.card {
    width: 300px;
    /* height: 200px; */
    border-radius: 15px;
    background-color: #fff;
    margin: 0 30px;
    padding: 20px;
    font-size: 0.9rem;
    margin-left: 10px;
    margin-right: 10px;
}
