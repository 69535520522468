@import './fonts.css';

.main {
    background-color: #f9ea2d;
    background-size: cover;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-family: 'Oswald-Regular', serif;
}

.section-one {
    font-family: 'Oswald-Regular', serif;
    background-image: url('../Assets/Images/s1-bg-img.jpeg');
    background-size: cover;
    background-repeat: repeat-x;
    background-position: center;
    display: grid;
}

.s1-content {
    text-align: left;
}

.header {
    display: flex;
    /* grid-template-rows: 1fr 1fr; */
    flex-direction: column;
    /* flex-wrap: wrap; */
    align-items: center;
}

.mfc-logo {
    height: 60px;
    margin-top: 20px;
}

.s1-title {
    text-align: center;
    color: #faea01;
    letter-spacing: -0.1rem;
    font-family: 'Oswald-Bold', sans-serif;
    font-size: 2.8rem;
    line-height: 1.5;
    background: linear-gradient(to right, #f9ff00, #3dc2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.s1-about {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 300;
    letter-spacing: -0.01rem;
    color: #dbd5d5;
    font-family: 'Oswald-Medium', sans-serif;
    text-align: justify;
    width: 80%;
    margin: auto;
}

.s1-form {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.mfc-form {
    display: grid;
    grid-gap: 1rem;
}

.input {
    margin-top: 10px;
    padding: 20px;
    border-radius: 5px;
    background-color: #5d5d5d;
    border: none;
    color: white;
}
.input:focus {
    border: 2px solid #faea01;
}
.input::placeholder {
    color: white;
}

.btn {
    padding: 18px;
    border: 2px solid #faea01;
    color: #faea01;
    background-color: unset;
    border-radius: 10px;
    margin-left: 2%;
    font-weight: bold;
    font-size: 1rem;
}

.btn:active {
    background-color: #f9ff00;
    color: #4b4b4b;
}

.section-two {
    /*display: grid;*/
    width: 80%;
    margin: auto;
}

.s2-title {
    font-size: 2.5rem;
    font-family: 'Oswald-Bold', sans-serif;
    text-align: center;
    background: linear-gradient(to right, #4b4b4b, #3dc2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.s2-subTitle {
    font-size: 1.2rem;
    font-family: 'Oswald-Medium', sans-serif;
    text-align: justify;
}

.s2-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
    margin: auto;
}
.dummy-text {
    color: #f9ea2d;
}
.s2-list-item {
    width: 90%;
    margin: auto;
}

.s2-listImg {
    height: 600px;
    display: block;
    margin-left: 0 auto;
    border-radius: 20px;
}

.s2-itemTitle {
    font-size: 1.4rem;
    text-align: center;
    font-family: 'Oswald-Bold', sans-serif;
    white-space: nowrap;
    background: linear-gradient(to right, #4b4b4b, #3dc2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.s2-itemSubTitle {
    font-family: 'Oswald-Regular', sans-serif;
    font-size: 1.1rem;
    text-align: justify;
    /* width: 80%; */
}

.section-three {
    background-color: #4b4b4b;
}

.s3-body {
    width: 80%;
    margin: auto;
}
.s3-items {
}
.s3-title {
    color: #f9ea2d;
    text-align: center;
    font-size: 1.8rem;
}
.s3-subTitle {
    font-size: 1.2rem;
    color: #fff;
    text-align: justify;
}
.s3-subTitle2 {
    color: #fff;
    font-size: 1.2rem;
    text-align: justify;
}

.section-four {
    margin: auto;
    text-align: center;
    width: 100%;
}

.s4-title {
    font-size: 1.7rem;
    white-space: nowrap;
    background: linear-gradient(to right, #4b4b4b, #3dc2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.marquee-card {
    margin: auto;
}

.footer {
    text-align: center;
    font-size: 1.1rem;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    padding-bottom: 15px;
}

.icon {
    color: #f9ea2d;
}

.copyright {
    padding-top: 15px;
}

/* Start of Tablet resizing */
@media screen and (min-device-width: 768px) and (max-width: 1199px) {
    .s1-title {
        font-size: 4.5rem;
    }
    .mfc-logo {
        height: 100px;
    }
    .mfc-form {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 0.5fr));
        padding: 10px;
    }
    .input {
        width: 100%;
        margin: 10px auto;
    }

    .btn {
        width: 60%;
        margin: auto;
    }
    .form-msg {
        padding: 10px;
        display: grid;
        grid-column: 1 / -1;
    }

    .s2-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 1.5fr));
        grid-gap: 1rem;
        justify-content: space-evenly;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
        /* padding: 10px; */
    }

    .s2-listImg {
        height: 600px;
        display: block;
        margin: 0;
    }
}

@media screen and (min-width: 556px) and (max-width: 767px) {
    .input {
        width: 80%;
        margin: 10px auto;
    }
    .btn {
        width: 50%;
        margin: auto;
    }

    .mfc-logo {
        height: 80px;
    }

    .s2-listImg {
        height: 600px;
        display: block;
        margin: 0 auto;
        border-radius: 20px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 851px) {
    .s2-img-2 {
        margin-left: 0;
    }
    .s2-img-4 {
        margin-left: 0;
    }
    .s2-itemTitle {
        text-align: left;
    }
}

@media screen and (min-device-width: 852px) and (max-width: 1199px) {
    .s2-img-2 {
        margin-left: 0;
    }
    .s2-img-4 {
        margin-left: 0;
    }
    .s2-itemTitle {
        text-align: left;
    }
    .section-four {
        width: 90%;
    }
    .marquee-card {
        width: 70%;
    }
}
/* end of tablet resizing*/

/* Desktop */
@media screen and (min-width: 1200px) {
    .s1-content {
        width: 60%;
        padding: 80px;
        margin: 0 auto;
    }
    .mfc-logo {
        height: 100px;
        margin: 0;
    }
    .s1-title {
        text-align: left;
        font-size: 4.5rem;
    }
    .s1-about {
        width: 100%;
    }

    .mfc-form {
        display: grid;
        grid-template-columns: repeat(2, 0.5fr);
    }
    .input {
        display: block;
        margin-left: 25%;
        width: 80%;
        margin-top: 0;
    }
    .btn {
        display: block;
        margin-left: 25%;
        width: 30%;
    }

    .section-two {
        width: 60%;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .s2-title {
        text-align: center;
    }

    .s2-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 0.5fr));
        grid-gap: 4rem;
        justify-content: space-evenly;
        justify-items: center;
        align-content: space-evenly;
        align-items: center;
        width: 90%;
    }
    /* .s2-img-2 {
        margin-bottom: 45px;
    }
    .s2-img-4 {
        margin-bottom: 53px;
    } */

    .s2-itemTitle {
        width: 120%;
    }

    .s2-itemTitle,
    .s2-itemSubTitle {
        text-align: justify;
    }

    .s2-listImg {
        margin-left: 0;
    }

    .s3-body {
        width: 60%;
    }

    .section-four {
        width: 80%;
    }
    .marquee-card {
        width: 50%;
    }
}
/* end of desktop*/
